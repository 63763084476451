import { usePaciente } from '@/lib/composables/usePaciente';
import { createComponent } from '@/lib/vue';
import { PacienteForm } from '../components/forms/PacienteForm';
import { PacienteParentes } from '../components/parentes/PacienteParentes';

export default createComponent({
  name: 'PacienteParentesPage',
  setup(props, ctx) {
    const { page, $title, handleSubmit } = usePaciente(ctx);
    return () => (
      <PacienteForm
        noDelete
        page={page}
        title={$title.value}
        onSubmit={handleSubmit}
      >
        <PacienteParentes />
      </PacienteForm>
    );
  },
});
