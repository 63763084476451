import { SetupContext } from '@vue/composition-api';

export const bindProps = props => ({ attrs: props });

export const passDownScopedSlots = (scopedSlots: SetupContext['slots']) =>
  Object.entries(scopedSlots).reduce(
    (obj, [slotName, slot]) => ({
      ...obj,
      [slotName]: props => slot && slot(props),
    }),
    {} as any,
  );
