import { PageSection } from '@/components/page/PageSection';
import { useValue } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { CepService } from '@/lib/services';
import { createComponent, watchRun } from '@/lib/vue';
import { IEnderecoModel, IForm, IFormSchema, IRequireField } from '@/typings';
import { computed } from '@vue/composition-api';
import { FormFields } from './FormFields';

interface IJsxProps {
  // model
  value?: IEnderecoModel;
  form: IForm<any>;
  noPais?: boolean;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (endereco: IEnderecoModel) => void;
}

export const EnderecoFields = createComponent<IJsxProps, IEvents>({
  name: 'EnderecoFields',
  props: {
    value: { type: Object, required: true },
    form: { type: Object, required: true },
    noPais: { type: Boolean, default: false },
  },
  setup(props: IProps, ctx) {
    const [$cepLoading, setCepLoading] = useValue(false);

    const $schema = computed<IFormSchema<IEnderecoModel>>(() => {
      return {
        enderecoCep: {
          label: 'CEP',
          type: 'text',
          mask: 'cep',
          loading: $cepLoading.value,
          clearable: true,
          layout: {
            maxWidth: 150,
            xs: 12,
          },
        },
        enderecoLogradouro: {
          label: 'Logradouro',
          type: 'text',
          layout: { sm: 8 },
        },
        enderecoNumero: {
          label: 'Número',
          type: 'text',
          layout: { sm: 4 },
        },
        enderecoComplemento: {
          label: 'Complemento',
          type: 'text',
          layout: { sm: 6 },
        },
        enderecoBairro: {
          label: 'Bairro',
          type: 'text',
          layout: { sm: 6 },
        },
        enderecoCidade: {
          label: 'Cidade',
          type: 'text',
          layout: {
            sm: 8,
            maxWidth: 500,
          },
        },
        enderecoUf: {
          label: 'UF',
          type: 'select',
          items: ConstantsHelper.estados,
          itemLabel: 'sigla',
          itemValue: 'sigla',
          layout: { maxWidth: 100 },
        },
        enderecoPais: {
          label: 'País',
          type: 'autocomplete',
          items: ConstantsHelper.paises,
          itemLabel: 'nome',
          itemValue: 'nome',
          hidden: props.noPais,
          layout: {
            maxWidth: 380,
            xs: 12,
          },
        },
      };
    });

    async function searchCep(searchValue: string | null | undefined) {
      if (
        !searchValue ||
        !props.value ||
        props.form.loading ||
        props.form.page.submitting
      ) {
        return;
      }

      setCepLoading(true);

      const cep = await CepService.cep(searchValue);

      setCepLoading(false);

      if (cep) {
        emitInput({
          enderecoCep: searchValue,
          enderecoLogradouro: cep.logradouro,
          enderecoNumero: props.value.enderecoNumero,
          enderecoComplemento: '', // cep.complemento,
          enderecoBairro: cep.bairro,
          enderecoCidade: cep.cidade,
          enderecoUf: cep.uf,
          enderecoPais: props.value.enderecoPais,
        });
      }
    }

    function emitInput(value: IEnderecoModel) {
      ctx.emit('input', value);
    }

    watchRun(
      () => props.value.enderecoCep,
      newValue => searchCep(newValue),
    );

    return () => (
      <PageSection divider title="Endereço">
        <FormFields
          slot="fields"
          form={props.form}
          schema={$schema.value}
          value={props.value}
          onInput={emitInput}
        />
      </PageSection>
    );
  },
});
