import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { useState } from '@/lib/composables';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { getEditValue } from '@/lib/form/edit';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { uuid } from '@/lib/helpers/uuid';
import { PacienteService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { PacienteState } from '@/store/modules/paciente.store';
import {
  IFormPage,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IPacienteFormModel,
  IPacienteParenteModel,
  IDataTableHeader,
} from '@/typings';
import { Ref } from '@vue/composition-api';
import { PacienteParentesForm } from './PacienteParentesForm';

export const PacienteParentes = createComponent({
  name: 'PacienteParentes',
  setup(props, ctx) {
    const headers: IDataTableHeader<IPacienteParenteModel>[] = [
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
      {
        text: 'Parentesco',
        value: 'parentesco',
        mapValue: v => v.parentesco,
      },
      {
        text: 'Profissão',
        value: 'profissao',
        mapValue: v => v.profissao,
      },
    ];

    const $model = useState(s => s.paciente.model);

    const { page, handleSubmit } = useFormPage({
      ctx,
      async submitCallback(dialogModel: IPacienteParenteModel) {
        if (!dialogModel.id) {
          return handleCreate(dialogModel);
        }

        return handleUpdate(dialogModel);
      },
    });

    const { btn, actions, handleCreate, handleUpdate } = useActions({
      page,
      handleSubmit,
      $model,
    });

    return () => (
      <IndexDataTable
        title="Parentes"
        btn={btn}
        headers={headers}
        items={$model.value.parentes}
        actions={actions}
      />
    );
  },
});

function useActions({
  page,
  handleSubmit,
  $model,
}: {
  page: IFormPage;
  handleSubmit: (model: IPacienteParenteModel) => Promise<void>;
  $model: Ref<IPacienteFormModel>;
}) {
  function showDialog({
    title,
    initialValue,
  }: {
    title: string;
    initialValue: IPacienteParenteModel | null;
  }) {
    return DialogHelpers.system.form({
      title,
      form: PacienteParentesForm,
      page,
      initialValue,
      onSubmit: handleSubmit,
    });
  }

  function handleNew() {
    return showDialog({
      title: 'Novo parente',
      initialValue: null,
    });
  }

  function handleEdit(id?: string | null) {
    const initialValue = getEditValue(id, $model.value.parentes);

    if (!initialValue) return handleNew();

    return showDialog({
      title: 'Editar parente',
      initialValue,
    });
  }

  function updateModel(parentes: IPacienteParenteModel[]) {
    return PacienteState.setModel({
      model: {
        ...$model.value,
        parentes,
      },
      modelChanged: true,
    });
  }

  async function removeMany(allIds: string[], items: IPacienteParenteModel[]) {
    const ids = items.filter(f => !f.new && !!f.id).map(v => v.id!);

    const result = await PacienteService.deleteManyParentes(ids, allIds);

    if (result) {
      updateModel($model.value.parentes.filter(f => !allIds.includes(f.id!)));
    }
  }

  const btn: IIndexDataTableButton = {
    text: 'Novo Parente',
    outlined: true,
    onClick: handleNew,
  };

  const actions: IIndexDataTableActions = {
    edit: handleEdit,
    removeMany,
  };

  return {
    btn,
    actions,

    async handleCreate(dialogModel: IPacienteParenteModel) {
      dialogModel.id = uuid();
      dialogModel.new = true;

      updateModel([...$model.value.parentes, dialogModel]);

      if ($model.value.id) {
        const parente = await PacienteService.createParente(
          $model.value.id,
          dialogModel,
        );

        if (parente) {
          updateModel([
            ...$model.value.parentes.filter(f => f.id !== dialogModel.id),
            parente,
          ]);
        }
      }
    },

    async handleUpdate(dialogModel: IPacienteParenteModel) {
      updateModel([
        ...$model.value.parentes.filter(v => v.id !== dialogModel.id),
        dialogModel,
      ]);

      if ($model.value.id && !dialogModel.new && dialogModel.id) {
        const parente = await PacienteService.updateParente(
          dialogModel.id,
          dialogModel,
        );

        if (parente) {
          updateModel([
            ...$model.value.parentes.filter(f => f.id !== dialogModel.id),
            parente,
          ]);
        }
      }
    },
  };
}
