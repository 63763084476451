import { PacienteService } from '@/lib/services';
import { PacienteState } from '@/store/modules/paciente.store';
import { computed, SetupContext } from '@vue/composition-api';
import { useState } from '.';
import { useFormPage } from './form/useFormPage';
import { useRoute } from './utils/useRouter';

export function usePaciente(ctx: SetupContext) {
  const $model = useState(s => s.paciente.model);

  const agendamentoId = useRoute().query.aid as string | null;

  const pacienteId = useRoute().params.id || null;

  PacienteState.setId(pacienteId);

  const $title = computed(() => {
    if ($model.value.geral.nome) {
      return `Paciente: ${$model.value.geral.nome}`;
    }
    return pacienteId ? 'Editar Paciente' : 'Novo Paciente';
  });

  async function handleDelete(id: string) {
    return PacienteService.delete(id);
  }

  const { page, handleSubmit, isEdit } = useFormPage({
    ctx,
    async submitCallback() {
      if (pacienteId) {
        return PacienteService.update({
          id: pacienteId,
          model: $model.value,
          agendamentoId,
        });
      } else {
        return PacienteService.create($model.value);
      }
    },
  });

  return {
    agendamentoId,
    pacienteId,
    $model,
    $title,
    page,
    isEdit,
    handleSubmit,
    handleDelete,
  };
}
