import { MyForm } from '@/components/form/MyForm';
import { MyPage } from '@/components/page/MyPage';
import { useState } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useRouter } from '@/lib/composables/utils/useRouter';
import {
  pacienteConvenioToFormModel,
  pacienteDadosPessoaisForm,
} from '@/lib/form';
import { PacienteService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import { PacienteState } from '@/store/modules/paciente.store';
import {
  IPacienteConvenioFormModel,
  IPacienteDadosPessoaisFormSchema,
  IPacienteFormModel,
  IPacienteParenteModel,
} from '@/typings';
import { computed, ComputedRef, SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {
  title: string;
  noDelete?: boolean;
}

interface IEvents extends IFormEvents<IPacienteFormModel> {}

export const PacienteForm = createComponent<IProps, IEvents>({
  name: 'PacienteForm',
  props: {
    page: { type: Object, required: true },
    title: { type: String, required: true },
    noDelete: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const { $model, $showDelete, indexRoute } = useComputeds(props, ctx);

    function handleCancel() {
      PacienteState.resetModel();

      return useRouter().push(indexRoute);
    }

    const { $form, emitSubmit, emitDelete } = usePacienteForm({
      props,
      ctx,
      $model,
    });

    return () => {
      const defaultSlot = ctx.slots.default?.({ $form });

      return (
        <MyPage title={props.title} form>
          <MyForm
            form={$form.value}
            noDelete={!$showDelete.value}
            onSubmit={emitSubmit}
            onCancel={handleCancel}
            onDelete={emitDelete}
          >
            {defaultSlot}
          </MyForm>
        </MyPage>
      );
    };
  },
});

function useComputeds(props: IProps, ctx: SetupContext) {
  const indexRoute = Routes.app.pacientes.index;

  const $model = useState(s => s.paciente.model);

  const { $userIsAdmin } = useAuthInfo();

  const $showDelete = computed(
    () => $userIsAdmin.value && !props.noDelete && !$model.value.incompleto,
  );

  return {
    $model,
    indexRoute,
    $userIsAdmin,
    $showDelete,
  };
}

function usePacienteForm({
  props,
  ctx,
  $model,
}: {
  props: IProps;
  ctx: SetupContext;
  $model: ComputedRef<IPacienteFormModel>;
}) {
  const $modelChanged = useState(s => s.paciente.modelChanged);

  const $requireFones = computed(
    () =>
      !$model.value.telefones.celular && !$model.value.telefones.telefoneCasa,
  );

  return useFormConfig<IPacienteFormModel, IPacienteDadosPessoaisFormSchema>({
    page: props.page,
    ctx,
    initialValue: $model.value,
    mapValueToValidate: () => $model.value,
    mapSchema: () => pacienteDadosPessoaisForm($requireFones),
    async loadEditCallback({ id }) {
      if ($modelChanged.value) return;

      const editValue = await PacienteService.getById(id);

      if (!editValue) return redirectError(404);

      const parentes: IPacienteParenteModel[] = editValue.parentes || [];
      const convenios: IPacienteConvenioFormModel[] = (
        editValue.convenios || []
      ).map(pacienteConvenioToFormModel);

      PacienteState.setModel({
        model: {
          id: editValue.id,
          createdAt: editValue.createdAt,
          incompleto: editValue.incompleto,
          parentes,
          convenios,
          geral: {
            nome: editValue.nome,
            codigo: editValue.codigo,
            dataNascimento: editValue.dataNascimento,
            sexo: editValue.sexo,
            email: editValue.email,
            cpf: editValue.cpf,
            rg: editValue.rg,
            comoConheceu: editValue.comoConheceu,
            observacao: editValue.observacao,
          },
          telefones: {
            celular: editValue.celular,
            telefoneCasa: editValue.telefoneCasa,
            telefoneTrabalho: editValue.telefoneTrabalho,
            aceitaSms: editValue.aceitaSms,
          },
          endereco: {
            enderecoCep: editValue.enderecoCep,
            enderecoLogradouro: editValue.enderecoLogradouro,
            enderecoNumero: editValue.enderecoNumero,
            enderecoComplemento: editValue.enderecoComplemento,
            enderecoBairro: editValue.enderecoBairro,
            enderecoCidade: editValue.enderecoCidade,
            enderecoUf: editValue.enderecoUf,
            enderecoPais: editValue.enderecoPais,
          },
          dadosComplementares: {
            naturalidade: editValue.naturalidade,
            naturalidadeUf: editValue.naturalidadeUf,
            nacionalidade: editValue.nacionalidade,
            etnia: editValue.etnia,
            religiao: editValue.religiao,
            estadoCivil: editValue.estadoCivil,
            escolaridade: editValue.escolaridade,
            profissao: editValue.profissao,
            obito: editValue.obito,
            causaMortis: editValue.causaMortis,
          },
          outrasInformacoes: {
            cns: editValue.cns,
            informacoesAdicionais: editValue.informacoesAdicionais,
          },
          imagem: {
            imagemId: editValue.imagem?.id || null,
            url:
              editValue.imagem?.thumbnailUrl || editValue.imagem?.url || null,
          },
        },
        modelChanged: false,
      });
    },
    validationErrorCallback: () => PacienteState.setHasError(true),
  });
}
