import { EnderecoFields } from '@/components/form/fields/EnderecoFields';
import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { IPerfilImagem, PerfilImagem } from '@/components/perfil/PerfilImagem';
import { FormHeader } from '@/components/typography/FormHeader';
import { useState } from '@/lib/composables';
import { pacienteDadosPessoaisForm } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { PacienteState } from '@/store/modules/paciente.store';
import {
  IEnderecoModel,
  IForm,
  IPacienteDadosPessoaisFormSchema,
  IPacienteFormModel,
  IPacienteGeralModel,
  IPacienteTelefonesModel,
} from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {
  form: IForm<IPacienteFormModel>;
}

export const PacienteDadosPessoais = createComponent<IProps>({
  name: 'PacienteDadosPessoais',
  props: {
    form: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $model = useState(s => s.paciente.model);

    const $requireFones = computed(
      () =>
        !$model.value.telefones.celular && !$model.value.telefones.telefoneCasa,
    );

    const $schema = computed<IPacienteDadosPessoaisFormSchema>(() =>
      pacienteDadosPessoaisForm($requireFones),
    );

    function handleInputEndereco(endereco: IEnderecoModel) {
      return PacienteState.setModel({
        model: {
          ...$model.value,
          endereco,
        },
        modelChanged: true,
      });
    }

    return () => (
      <div class="flex flex-col">
        <GeralFields form={props.form} schema={$schema.value} />

        <Telefones form={props.form} schema={$schema.value} />

        <EnderecoFields
          form={props.form}
          value={$model.value.endereco}
          onInput={handleInputEndereco}
        />
      </div>
    );
  },
});

interface IFieldsProps {
  form: IForm<IPacienteFormModel>;
  schema: IPacienteDadosPessoaisFormSchema;
}

const GeralFields = createComponent<IFieldsProps>({
  name: 'PacienteGeralFields',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $model = useState(s => s.paciente.model);

    function handleInputGeral(geral: IPacienteGeralModel) {
      return PacienteState.setModel({
        model: {
          ...$model.value,
          geral,
        },
        modelChanged: true,
      });
    }

    async function handleImagemInput({ image, upload }: IPerfilImagem) {
      PacienteState.setImagem({ imagemId: null, url: image });

      props.form.page.submitting = true;

      PacienteState.setImagem({
        imagemId: await upload,
        url: image,
      });

      props.form.page.submitting = false;
    }

    function handleImagemRemove() {
      PacienteState.setImagem({ imagemId: null, url: null });
    }

    return () => {
      const id = props.form.page.id;
      const createdAt = DateHelpers.formatDateAndHour($model.value.createdAt);

      return (
        <PageSection titleMinWidth={250}>
          <div class="flex flex-col" slot="title">
            <FormHeader title="Geral" />

            <PerfilImagem
              value={$model.value.imagem.url}
              name={$model.value.geral.nome}
              onInput={handleImagemInput}
              onRemove={handleImagemRemove}
            />

            {id && (
              <v-btn to={Routes.app.prontuario(id)} outlined color="secondary">
                Ver prontuário
              </v-btn>
            )}

            {createdAt && (
              <div class="my-4 text-coolGray-700 text-small">
                Cadastrado em {createdAt}
              </div>
            )}
          </div>

          <FormFields
            slot="fields"
            form={props.form}
            schema={props.schema.geral}
            value={$model.value.geral}
            onInput={handleInputGeral}
          />
        </PageSection>
      );
    };
  },
});

const Telefones = createComponent<IFieldsProps>({
  name: 'PacienteTelefones',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $model = useState(s => s.paciente.model);

    function handleInputTelefones(telefones: IPacienteTelefonesModel) {
      return PacienteState.setModel({
        model: {
          ...$model.value,
          telefones,
        },
        modelChanged: true,
      });
    }

    return () => (
      <PageSection divider title="Telefones">
        <FormFields
          slot="fields"
          form={props.form}
          schema={props.schema.telefones}
          value={$model.value.telefones}
          onInput={handleInputTelefones}
        />
      </PageSection>
    );
  },
});
