import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';
import { computed } from '@vue/composition-api';

export default createComponent({
  name: 'PacienteEditSidebar',
  setup(props, ctx) {
    const $pacienteId = useState(s => s.paciente.model.id!);

    const $hasError = useState(s => s.paciente.hasError);

    const $menus = computed<IMenu[]>(() => {
      const pacienteId = $pacienteId.value;

      return [
        {
          text: 'Editar Paciente',
          header: true,
        },
        {
          text: 'Dados pessoais',
          icon: MyIcons.pacienteDadosPessoais,
          to: Routes.app.pacientes.edit.index(pacienteId),
          hasError: $hasError.value,
        },
        {
          text: 'Dados complementares',
          icon: MyIcons.pacienteDadosComplementares,
          to: Routes.app.pacientes.edit.dadosComplementares(pacienteId),
        },
        {
          text: 'Parentes',
          icon: MyIcons.pacienteParentes,
          to: Routes.app.pacientes.edit.parentes(pacienteId),
        },
        {
          text: 'Convênios',
          icon: MyIcons.convenio,
          to: Routes.app.pacientes.edit.convenios(pacienteId),
        },
        {
          text: 'Histórico de consultas',
          icon: MyIcons.historico,
          to: Routes.app.pacientes.edit.historicoConsultas(pacienteId),
        },
        {
          text: 'Log de alterações',
          icon: MyIcons.pacienteLogAlteracoes,
          to: Routes.app.pacientes.edit.logAlteracoes(pacienteId),
        },
      ];
    });

    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={$menus.value} />
      </ContextSidebar>
    );
  },
});
