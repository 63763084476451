import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IItem, IMenuSelected } from '@/typings';
import { computed, ComputedRef, Ref } from '@vue/composition-api';
import isString from 'lodash/isString';

interface IProps {
  selected: Array<IItem | string>;
  menus: readonly IMenuSelected[];
}

export const BtnSelectedRows = createComponent<IProps>({
  name: 'BtnSelectedRows',
  props: {
    selected: { type: Array, required: true },
    menus: { type: Array, required: true },
  },
  setup(props, ctx) {
    const $show = computed(
      () => !!props.selected.length && !!props.menus.length,
    );

    const $label = computed(() => {
      const num = props.selected.length;
      const label = num > 1 ? 'selecionados' : 'selecionado';

      return `${num} ${label}`;
    });

    const $ids = computed(() =>
      props.selected.map(v => (isString(v) ? v : v.id)),
    );

    return () => (
      <v-fade-transition>
        {$show.value && (
          <v-menu offset-y scopedSlots={{ activator: menuActivator($label) }}>
            {listMenus({ props, $ids })}
          </v-menu>
        )}
      </v-fade-transition>
    );
  },
});

const menuActivator =
  ($label: Ref<string>) =>
  ({ on }) =>
    (
      <v-btn color="primary" outlined class="pr-2" {...{ on }}>
        <v-icon left>{MyIcons.selectedRows}</v-icon>

        <span>{$label.value}</span>

        <v-icon right>{MyIcons.menuDown}</v-icon>
      </v-btn>
    );

const listMenus = ({
  props,
  $ids,
}: {
  props: IProps;
  $ids: ComputedRef<string[]>;
}) => (
  <v-list>
    {props.menus.map(menu => (
      <v-list-item
        key={menu.text}
        onClick={() => menu.action($ids.value, props.selected)}
      >
        <v-list-item-action>
          <v-icon>{menu.icon}</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{menu.text}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    ))}
  </v-list>
);
