import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { LookupsConfigs } from '@/lib/form/lookups';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IPacienteConvenioFormModel,
  IPacienteConvenioFormSchema,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IDialogFormProps<IPacienteConvenioFormModel> {}

interface IEvents extends IFormEvents<IPacienteConvenioFormModel> {}

export const PacienteConvenioForm = createComponent<IProps, IEvents>({
  name: 'PacienteConvenioForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useForm(props, ctx);

    watchRun(
      () => $form.value.model.carteirinha.semVencimento,
      semVencimento => {
        if (semVencimento) {
          $form.value.model.carteirinha.vencimento = null;
        }
      },
    );

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <PageSection>
          <FormHeader title="Convênio" />

          <FormFields
            form={$form.value}
            schema={$schema.value.convenio}
            v-model={$form.value.model.convenio}
          />
        </PageSection>

        <PageSection>
          <FormHeader title="Carteirinha" />

          <FormFields
            form={$form.value}
            schema={$schema.value.carteirinha}
            v-model={$form.value.model.carteirinha}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const defaultValue: IPacienteConvenioFormModel = {
    convenio: {
      convenioId: null,
      convenioNome: null,
      planoId: null,
      planoNome: null,
      acomodacao: null,
    },
    carteirinha: {
      vencimento: null,
      semVencimento: false,
      numeroCarteirinha: null,
    },
  };

  return useDialogFormConfig<
    IPacienteConvenioFormModel,
    IPacienteConvenioFormSchema
  >({
    page: props.page,
    ctx,
    initialValue: props.initialValue || defaultValue,
    mapSchema: (model, $form) => ({
      convenio: {
        convenioId: {
          label: 'Convênio',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.convenio({
            noParticular: true,
            onModelChange(value) {
              $form.value.model.convenio.convenioNome = value?.nome;
              $form.value.model.convenio.planoId = null;
              $form.value.model.convenio.planoNome = null;
            },
          }),
          validations: {
            required: true,
          },
        },
        planoId: {
          label: 'Plano',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.convenioPlano({
            convenioId: model.convenio.convenioId!,
            onModelChange(value) {
              $form.value.model.convenio.planoNome = value?.nome;
            },
            noCache: true,
          }),
          disabled: !model.convenio.convenioId,
        },
        acomodacao: {
          label: 'Acomodação',
          type: 'text',
          hideDetails: true,
        },
      },
      carteirinha: {
        numeroCarteirinha: {
          label: 'Número carteirinha',
          type: 'text',
          maxLength: 20,
          validations: { required: true },
        },
        vencimento: {
          label: 'Vencimento',
          type: 'date',
          disabled: model.carteirinha.semVencimento,
          validations: {
            required: !model.carteirinha.semVencimento,
          },
          layout: { xs: 6 },
        },
        semVencimento: {
          label: 'Sem vencimento',
          type: 'checkbox',
          layout: { xs: 6 },
        },
      },
    }),
  });
}
