import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { MyPage } from '@/components/page/MyPage';
import { useValue } from '@/lib/composables';
import { usePaciente } from '@/lib/composables/usePaciente';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader } from '@/typings';

export default createComponent({
  name: 'PacienteHistoricoConsultasPage',
  setup(props, ctx) {
    const { $title } = usePaciente(ctx);

    const [$items] = useValue([]);

    const headers: IDataTableHeader[] = [
      {
        text: 'Data',
        value: 'data',
      },
      {
        text: 'Horário',
        value: 'hora',
      },
      {
        text: 'Profissional',
        value: 'profissional',
      },
      {
        text: 'Procedimento',
        value: 'procedimento',
      },
      {
        text: 'Status',
        value: 'status',
      },
    ];

    return () => (
      <MyPage title={$title.value}>
        <IndexDataTable
          selectable
          title="Histórico de consultas"
          headers={headers}
          items={$items.value}
          // loading={loading}
        />
      </MyPage>
    );
  },
});
