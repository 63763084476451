import { useValue } from '@/lib/composables';
import { passDownScopedSlots } from '@/lib/helpers/jsx';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import {
  IIndexDataTableActions,
  IIndexDataTableButton,
  IMenuSelected,
  IDataTableHeader,
} from '@/typings';
import { computed, ComputedRef, Ref, SetupContext } from '@vue/composition-api';
import { BtnSelectedRows } from '../buttons/BtnSelectedRows';
import { PageSection } from '../page/PageSection';
import { FormHeader } from '../typography/FormHeader';
import { DataTable } from './DataTable';

interface IProps {
  title?: string | null;
  titleText?: string | null;
  btn?: IIndexDataTableButton | null;
  headers: readonly IDataTableHeader[];
  items?: readonly any[] | null;
  loading?: boolean;
  selectable?: boolean;
  actions?: IIndexDataTableActions | null;
  noSearch?: boolean;
  divider?: boolean;
  draggable?: boolean;
}

interface IEvents {
  onReorder: (items: any[]) => void;
}

export const IndexDataTable = createComponent<IProps, IEvents>({
  name: 'IndexDataTable',
  props: {
    title: { type: String },
    titleText: { type: String },
    btn: { type: Object },
    headers: { type: Array, required: true },
    items: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    selectable: { type: Boolean, default: true },
    actions: { type: Object },
    noSearch: { type: Boolean, default: false },
    divider: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const [$selected, setSelected] = useValue<any[]>([]);

    const { $button, $selectedMenus, $showActionsRow } = useComputeds({
      props,
      $selected,
    });

    const { emitReorder } = useEvents(ctx);

    return () => (
      <PageSection divider={props.divider}>
        {(props.title || props.titleText) && (
          <FormHeader title={props.title} text={props.titleText} />
        )}

        {actionsRow({
          ctx,
          $selected,
          $showActionsRow,
          $button,
          $selectedMenus,
        })}

        <DataTable
          showSelect={props.selectable}
          headers={props.headers}
          items={props.items}
          loading={props.loading}
          actions={props.actions}
          noSearch={props.noSearch}
          draggable={props.draggable}
          scopedSlots={passDownScopedSlots(ctx.slots)}
          selectedItems={$selected.value}
          onSelect={setSelected}
          onReorder={emitReorder}
        />
      </PageSection>
    );
  },
});

function useComputeds({
  props,
  $selected,
}: {
  props: IProps;
  $selected: Ref<any[]>;
}) {
  const $button = computed<IIndexDataTableButton>(() => ({
    to: null,
    onClick: () => null,
    icon: MyIcons.new,
    text: '',
    color: 'primary',
    outlined: false,
    ...props.btn,
  }));

  const $showActionsRow = computed(
    () => !!props.btn || (!!props.selectable && !!props.actions?.removeMany),
  );

  const $selectedMenus = computed<IMenuSelected[]>(() => {
    let menus: IMenuSelected[] = [];

    const selecionado =
      $selected.value.length > 1 ? 'selecionados' : 'selecionado';

    if (props.actions) {
      if (props.actions.removeMany) {
        menus.push({
          text: `Excluir ${selecionado}`,
          icon: MyIcons.remove,
          action: props.actions.removeMany,
        });
      }

      if (props.actions.selectedMenus) {
        menus = [...menus, ...props.actions.selectedMenus];
      }
    }

    return menus;
  });

  return {
    $button,
    $showActionsRow,
    $selectedMenus,
  };
}

function useEvents(ctx: SetupContext) {
  function emitReorder(items: any[]) {
    ctx.emit('reorder', items);
  }

  return { emitReorder };
}

const actionsRow = ({
  ctx,
  $selected,
  $showActionsRow,
  $button,
  $selectedMenus,
}: {
  ctx: SetupContext;
  $selected: Ref<any[]>;
  $showActionsRow: Ref<boolean>;
  $button: Ref<IIndexDataTableButton>;
  $selectedMenus: ComputedRef<IMenuSelected[]>;
}) => {
  const actionsSlot = ctx.slots.actions?.();

  return (
    $showActionsRow.value && (
      <div class="flex mt-2 mb-4">
        <BtnSelectedRows
          selected={$selected.value}
          menus={$selectedMenus.value}
        />

        <v-spacer />

        {actionsSlot || (
          <v-btn
            color={$button.value.color}
            outlined={$button.value.outlined}
            to={$button.value.to}
            onClick={$button.value.onClick}
          >
            <v-icon left>{$button.value.icon}</v-icon>
            {$button.value.text}
          </v-btn>
        )}
      </div>
    )
  );
};
