import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { InputSearch } from '@/components/form/inputs/InputSearch';
import { MyPage } from '@/components/page/MyPage';
import { QueryGraphql } from '@/graphql/query';
import { useValue } from '@/lib/composables';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { formatTelefone } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { pacienteWhere } from '@/lib/helpers/models/paciente';
import { PacienteService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { PacienteState } from '@/store/modules/paciente.store';
import {
  IDataTableHeader,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IPacienteItem,
  IPacientesQuery,
  IPacientesQueryVariables,
} from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';

export default createComponent({
  name: 'PacientesPage',
  setup(props, ctx) {
    const { $search, actions, btn, headers } = useData(ctx);

    const { $data, $loading } = usePacientesQuery($search);

    return () => (
      <MyPage title="Pacientes">
        <InputSearch
          placeholder="Pesquisar por nome, CPF, telefone, código..."
          v-model={$search.value}
        />

        <IndexDataTable
          class="-mt-4"
          noSearch
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </MyPage>
    );
  },
});

function useEvents() {
  function onNovoPaciente() {
    PacienteState.resetModel();

    useRouter().push(Routes.app.pacientes.new.index);
  }

  function editRouteFn(id: string) {
    return Routes.app.pacientes.edit.index(id);
  }

  PacienteState.resetModel();

  return { onNovoPaciente, editRouteFn };
}

function useData(ctx: SetupContext) {
  const { onNovoPaciente, editRouteFn } = useEvents();

  const [$search] = useValue('');

  const btn: IIndexDataTableButton = {
    text: 'Novo Paciente',
    onClick: onNovoPaciente,
  };

  const actions: IIndexDataTableActions = {
    editRoute: editRouteFn,
    removeMany: PacienteService.deleteMany,
  };

  const headers: IDataTableHeader<IPacienteItem>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
      to: Routes.app.prontuario,
    },
    {
      text: 'Telefone',
      value: 'telefone',
      mapValue: v => v.telefone,
    },
    {
      text: 'Código',
      value: 'codigo',
      mapValue: v => v.codigo,
    },
    {
      text: 'Data de nascimento',
      value: 'dataNascimento',
      mapValue: v => v.dataNascimento,
    },
    {
      text: 'Convênios',
      value: 'convenios',
      mapValue: v => v.convenios,
    },
  ];

  return { $search, btn, actions, headers };
}

function usePacientesQuery($search: Ref<string>) {
  return useQueryConfig<
    IPacientesQuery,
    IPacienteItem[],
    IPacientesQueryVariables
  >({
    query: QueryGraphql.PacientesQuery,
    variables: () => ({ where: pacienteWhere($search.value) }),
    mapData: result =>
      result?.pacientes.nodes.map(v => ({
        id: v.id,
        nome: v.nome,
        telefone: formatTelefone(v.celular || v.telefoneCasa),
        codigo: v.codigo,
        dataNascimento: DateHelpers.formatDate(v.dataNascimento),
        convenios: v.convenios?.map(v => v.convenio.nome).join(', '),
      })) || [],
  });
}
