import { usePaciente } from '@/lib/composables/usePaciente';
import { createComponent } from '@/lib/vue';
import { PacienteDadosPessoais } from '../components/forms/PacienteDadosPessoais';
import { PacienteForm } from '../components/forms/PacienteForm';

export default createComponent({
  name: 'PacienteDadosPessoaisPage',
  setup(props, ctx) {
    const { page, $title, handleDelete, handleSubmit } = usePaciente(ctx);

    return () => (
      <PacienteForm
        page={page}
        title={$title.value}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        scopedSlots={{
          default: ({ $form }) => [
            <PacienteDadosPessoais form={$form.value} />,
          ],
        }}
      />
    );
  },
});
