import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { useState } from '@/lib/composables';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { getEditValue, pacienteConvenioToFormModel } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { uuid } from '@/lib/helpers/uuid';
import { PacienteService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { PacienteState } from '@/store/modules/paciente.store';
import {
  IFormPage,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IPacienteConvenioFormModel,
  IPacienteFormModel,
  IDataTableHeader,
} from '@/typings';
import { Ref } from '@vue/composition-api';
import { PacienteConvenioForm } from './PacienteConvenioForm';

export const PacienteConvenios = createComponent({
  name: 'PacienteConvenios',
  setup(props, ctx) {
    const $model = useState(s => s.paciente.model);

    const headers: Array<IDataTableHeader<IPacienteConvenioFormModel>> = [
      {
        text: 'Convênio',
        value: 'convenio',
        mapValue: v => v.convenio.convenioNome,
      },
      {
        text: 'Plano',
        value: 'plano',
        mapValue: v => v.convenio.planoNome,
      },
      {
        text: 'Número da carteirinha',
        value: 'numeroCarteirinha',
        mapValue: v => v.carteirinha.numeroCarteirinha,
      },
      {
        text: 'Vencimento',
        value: 'vencimento',
        mapValue: v => DateHelpers.formatDate(v.carteirinha.vencimento),
      },
    ];

    const { page, handleSubmit } = useFormPage({
      ctx,
      async submitCallback(dialogModel: IPacienteConvenioFormModel) {
        if (!dialogModel.id) {
          return handleCreate(dialogModel);
        }
        return handleUpdate(dialogModel);
      },
    });

    const { btn, actions, handleCreate, handleUpdate } = useActions({
      $model,
      page,
      handleSubmit,
    });

    return () => (
      <IndexDataTable
        title="Convênios"
        btn={btn}
        headers={headers}
        items={$model.value.convenios}
        actions={actions}
      />
    );
  },
});

function useActions({
  $model,
  page,
  handleSubmit,
}: {
  $model: Ref<IPacienteFormModel>;
  page: IFormPage;
  handleSubmit: (model: IPacienteConvenioFormModel) => Promise<void>;
}) {
  function showDialog({
    title,
    initialValue,
  }: {
    title: string;
    initialValue: IPacienteConvenioFormModel | null;
  }) {
    return DialogHelpers.system.form({
      title,
      form: PacienteConvenioForm,
      page,
      initialValue,
      onSubmit: handleSubmit,
    });
  }

  function handleNew() {
    return showDialog({
      title: 'Novo convênio',
      initialValue: null,
    });
  }

  function handleEdit(id?: string | null) {
    const initialValue = getEditValue(id, $model.value.convenios);

    if (!initialValue) return handleNew();

    return showDialog({
      title: 'Editar convênio',
      initialValue,
    });
  }

  function updateModel(convenios: IPacienteConvenioFormModel[]) {
    return PacienteState.setModel({
      model: {
        ...$model.value,
        convenios,
      },
      modelChanged: true,
    });
  }

  async function removeMany(
    allIds: string[],
    items: IPacienteConvenioFormModel[],
  ) {
    const ids = items.filter(f => !f.new && !!f.id).map(v => v.id!);

    const result = await PacienteService.deleteManyConvenios(ids, allIds);

    if (result) {
      updateModel($model.value.convenios.filter(f => !allIds.includes(f.id!)));
    }
  }

  const btn: IIndexDataTableButton = {
    text: 'Novo Convênio',
    outlined: true,
    onClick: handleNew,
  };

  const actions: IIndexDataTableActions = {
    edit: handleEdit,
    removeMany,
  };

  return {
    btn,
    actions,

    async handleCreate(dialogModel: IPacienteConvenioFormModel) {
      dialogModel.id = uuid();
      dialogModel.new = true;

      updateModel([...$model.value.convenios, dialogModel]);

      if ($model.value.id) {
        const convenio = await PacienteService.createConvenio(
          $model.value.id,
          dialogModel,
        );

        if (convenio) {
          updateModel([
            ...$model.value.convenios.filter(f => f.id !== dialogModel.id),
            pacienteConvenioToFormModel(convenio),
          ]);
        }
      }
    },

    async handleUpdate(dialogModel: IPacienteConvenioFormModel) {
      updateModel([
        ...$model.value.convenios.filter(v => v.id !== dialogModel.id),
        dialogModel,
      ]);

      if ($model.value.id && !dialogModel.new && dialogModel.id) {
        const convenio = await PacienteService.updateConvenio(
          dialogModel.id,
          dialogModel,
        );

        if (convenio) {
          updateModel([
            ...$model.value.convenios.filter(f => f.id !== dialogModel.id),
            pacienteConvenioToFormModel(convenio),
          ]);
        }
      }
    },
  };
}
