import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { useState } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { createComponent } from '@/lib/vue';
import { PacienteState } from '@/store/modules/paciente.store';
import {
  IForm,
  IPacienteDadosComplementaresFormSchema,
  IPacienteDadosComplementaresModel,
  IPacienteFormModel,
  IPacienteOutrasInformacoesModel,
} from '@/typings';
import { computed, Ref } from '@vue/composition-api';

interface IProps {
  form: IForm<any>;
}

export const PacienteDadosComplementares = createComponent<IProps>({
  name: 'PacienteDadosComplementares',
  props: {
    form: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $model, $schema } = useComputeds();

    const { handleInputDadosComplementares, handleInputOutrasInformacoes } =
      useEvents($model);

    return () => (
      <div class="flex flex-col">
        <PageSection title="Dados complementares">
          <FormFields
            slot="fields"
            form={props.form}
            schema={$schema.value.dadosComplementares}
            v-model={$model.value.dadosComplementares}
            onInput={handleInputDadosComplementares}
          />
        </PageSection>

        <PageSection divider title="Outras informações">
          <FormFields
            slot="fields"
            form={props.form}
            schema={$schema.value.outrasInformacoes}
            v-model={$model.value.outrasInformacoes}
            onInput={handleInputOutrasInformacoes}
          />
        </PageSection>
      </div>
    );
  },
});

function useComputeds() {
  const $model = useState(s => s.paciente.model);

  const $schema = computed<IPacienteDadosComplementaresFormSchema>(() => ({
    dadosComplementares: {
      naturalidade: {
        label: 'Naturalidade',
        type: 'text',
        layout: { sm: 6 },
      },
      naturalidadeUf: {
        label: 'Naturalidade UF',
        type: 'select',
        items: ConstantsHelper.estados,
        itemLabel: 'sigla',
        itemValue: 'sigla',
        clearable: true,
        layout: { maxWidth: 200 },
      },
      nacionalidade: {
        label: 'Nacionalidade',
        type: 'autocomplete',
        items: ConstantsHelper.paises,
        itemLabel: 'nome',
        itemValue: 'nome',
        layout: { sm: 6 },
      },
      etnia: {
        label: 'Etnia',
        type: 'autocomplete',
        list: ConstantsHelper.etnias,
        layout: { sm: 6 },
      },
      religiao: {
        label: 'Religião',
        type: 'text',
        layout: { sm: 6 },
      },
      estadoCivil: {
        label: 'Estado civil',
        type: 'autocomplete',
        list: ConstantsHelper.estadosCivis,
        layout: { sm: 6 },
      },
      escolaridade: {
        label: 'Escolaridade',
        type: 'autocomplete',
        list: ConstantsHelper.escolaridades,
        layout: { sm: 6 },
      },
      profissao: {
        label: 'Profissão',
        type: 'text',
        layout: { sm: 6 },
      },
      obito: {
        label: 'Óbito',
        type: 'checkbox',
        layout: { sm: 6 },
      },
      causaMortis: {
        label: 'Causa mortis',
        type: 'autocomplete',
        itemLabel: 'descricao',
        hidden: !$model.value.dadosComplementares.obito,
        lookup: LookupsConfigs.cid10Lookup(),
        layout: { sm: 6 },
      },
    },
    outrasInformacoes: {
      cns: {
        label: 'CNS',
        type: 'text',
        maxLength: 15,
        layout: { width: 260 },
      },
      informacoesAdicionais: {
        label: 'Informações adicionais',
        type: 'textarea',
      },
    },
  }));

  return { $model, $schema };
}

function useEvents($model: Ref<IPacienteFormModel>) {
  function handleInputDadosComplementares(
    dadosComplementares: IPacienteDadosComplementaresModel,
  ) {
    return PacienteState.setModel({
      model: {
        ...$model.value,
        dadosComplementares,
      },
      modelChanged: true,
    });
  }

  function handleInputOutrasInformacoes(
    outrasInformacoes: IPacienteOutrasInformacoesModel,
  ) {
    return PacienteState.setModel({
      model: {
        ...$model.value,
        outrasInformacoes,
      },
      modelChanged: true,
    });
  }

  return {
    handleInputDadosComplementares,
    handleInputOutrasInformacoes,
  };
}
