import { usePaciente } from '@/lib/composables/usePaciente';
import { createComponent } from '@/lib/vue';
import { PacienteConvenios } from '../components/convenio/PacienteConvenios';
import { PacienteForm } from '../components/forms/PacienteForm';

export default createComponent({
  name: 'PacienteConveniosPage',
  setup(props, ctx) {
    const { page, handleSubmit, $title } = usePaciente(ctx);

    return () => (
      <PacienteForm
        noDelete
        page={page}
        title={$title.value}
        onSubmit={handleSubmit}
      >
        <PacienteConvenios />
      </PacienteForm>
    );
  },
});
