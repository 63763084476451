import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { IImageUploadDialogConfirmation } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps {
  value: string | null | undefined;
  name: string | null | undefined;
  logo?: boolean;
}

export type IPerfilImagem = Omit<IImageUploadDialogConfirmation, 'confirmed'>;

interface IEvents {
  onInput(payload: IPerfilImagem): void;
  onRemove(): void;
}

export const PerfilImagem = createComponent<IProps, IEvents>({
  name: 'PerfilImagem',
  props: {
    value: { type: String },
    name: { type: String },
    logo: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const { emitRemove, openUploadDialog } = useEvents(ctx);

    const title = props.logo ? 'Imagem do logo' : 'Foto';
    const size = props.logo ? 100 : 80;
    const icon = props.logo ? MyIcons.image : MyIcons.account;

    return () => (
      <div class="flex flex-col mt-4 mb-8">
        <div class="mb-2 font-normal text-coolGray-800">{title}</div>

        <div class="flex items-center">
          <div class="mr-2">
            <v-avatar color={MyTheme.gray300} size={size} tile={props.logo}>
              {props.value ? (
                <img src={props.value} alt={props.name || title} />
              ) : (
                <v-icon color={MyTheme.gray50}>{icon}</v-icon>
              )}
            </v-avatar>
          </div>

          <div class="flex flex-col items-start">
            <v-btn
              color="secondary"
              text
              small
              onClick={openUploadDialog}
              class="mb-2"
            >
              <v-icon left>
                {props.value ? MyIcons.imageEdit : MyIcons.imageInsert}
              </v-icon>

              {props.value ? 'Editar' : 'Inserir'}
            </v-btn>

            {props.value && (
              <v-btn color="secondary" text small onClick={emitRemove}>
                <v-icon left>{MyIcons.imageRemove}</v-icon>
                Remover
              </v-btn>
            )}
          </div>
        </div>

        {props.logo && (
          <div class="m-2 text-coolGray-600 text-small">
            Dimensão: 200x200 px
          </div>
        )}
      </div>
    );
  },
});

function useEvents(ctx: SetupContext) {
  function emitInput(payload: IPerfilImagem) {
    ctx.emit('input', payload);
  }
  return {
    emitInput,

    emitRemove() {
      ctx.emit('remove');
    },

    async openUploadDialog() {
      const { confirmed, image, upload } =
        await DialogHelpers.system.uploadImage({
          mode: 'tabs',
        });

      if (confirmed) {
        emitInput({ image, upload });
      }
    },
  };
}
