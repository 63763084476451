import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';
import { computed } from '@vue/composition-api';

export default createComponent({
  name: 'PacienteNewSidebar',
  setup(props, ctx) {
    const $hasError = useState(s => s.paciente.hasError);

    const $menus = computed<IMenu[]>(() => [
      {
        text: 'Novo Paciente',
        header: true,
      },
      {
        text: 'Dados pessoais',
        icon: MyIcons.pacienteDadosPessoais,
        to: Routes.app.pacientes.new.index,
        hasError: $hasError.value,
      },
      {
        text: 'Dados complementares',
        icon: MyIcons.pacienteDadosComplementares,
        to: Routes.app.pacientes.new.dadosComplementares,
      },
      {
        text: 'Parentes',
        icon: MyIcons.pacienteParentes,
        to: Routes.app.pacientes.new.parentes,
      },
      {
        text: 'Convênios',
        icon: MyIcons.convenio,
        to: Routes.app.pacientes.new.convenios,
      },
    ]);

    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={$menus.value} />
      </ContextSidebar>
    );
  },
});
