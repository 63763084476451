import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { ConstantsHelper } from '@/lib/constants/helper';
import { createComponent } from '@/lib/vue';
import { IFormSchema, IPacienteParenteModel } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IDialogFormProps<IPacienteParenteModel> {}

interface IEvents extends IFormEvents<IPacienteParenteModel> {}

export const PacienteParentesForm = createComponent<IProps, IEvents>({
  name: 'PacienteParentesForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useParentesForm(
      props,
      ctx,
    );

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useParentesForm(props: IProps, ctx: SetupContext) {
  const defaultValue: IPacienteParenteModel = {
    nome: null,
    parentesco: null,
    profissao: null,
  };

  return useDialogFormConfig<
    IPacienteParenteModel,
    IFormSchema<IPacienteParenteModel>
  >({
    page: props.page,
    ctx,
    initialValue: props.initialValue || defaultValue,
    mapSchema: () => ({
      nome: {
        label: 'Nome',
        type: 'text',
        validations: {
          required: true,
        },
      },
      parentesco: {
        label: 'Parentesco',
        type: 'autocomplete',
        list: ConstantsHelper.parentescos,
        menuProps: { maxHeight: 150 },
        validations: { required: true },
      },
      profissao: {
        label: 'Profissão',
        type: 'text',
      },
    }),
  });
}
